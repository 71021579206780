// TODO: When the Nuxt 3 Storybook module is released, move this in the app.config.ts file

import { DropdownOption } from './types/dropdown';
import Continent from './types/enums/continents';
import GeographicZoneSlug from './types/enums/geographicZoneSlug.enum';
import HEADER_MENUS_ENUM from './types/enums/headerMenus.enum';
import HouseCode from './types/enums/houseCode.enum';
import INSPIRATION_CATEGORY_SLUG from './types/enums/inspirationCategorySlug.enum';
import PRODUCT_LABEL from './types/enums/productLabel.enum';
import PRODUCT_TAB from './types/enums/productTab.enum';
import PROPOSITION_LEFT_MENU_ENUM from './types/enums/propositionLeftMenu.enum';
import TRAVEL_STYLE_SLUG from './types/enums/travelStyleSlug.enum';
import WpContinent from './types/enums/wpContinents';
import { FilterDefinition } from './types/filters';
import { Season } from './types/models/common';

export const BREAKPOINTS = {
  mobileSm: 425,
  mobile: 768,
  tablet: 1024,
  desktop: 1280,
  desktopLg: 1440
};

export const PRODUCT_TABS = Object.values(PRODUCT_TAB);
export const COUNTRY_TABS = [
  'Quand partir ?',
  'Formalités',
  'Que voir, que faire ?',
  'Carnet pratique',
  'Culture du voyage'
];

export const MONTHS = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre'
];

export const SEASONS: Season[] = [
  {
    id: 'winter',
    name: 'Hiver',
    icon: 'snowflake'
  },
  {
    id: 'spring',
    name: 'Printemps',
    icon: 'flower'
  },
  {
    id: 'summer',
    name: 'Été',
    icon: 'sun'
  },
  {
    id: 'autumn',
    name: 'Automne',
    icon: 'leaf'
  }
];

export const FILTERS_ORDERING: DropdownOption[] = [
  {
    label: 'Prix',
    type: 'price',
    value: 'price:asc',
    description: '(croissant)'
  },
  {
    label: 'Prix',
    type: 'price',
    value: 'price:desc',
    description: '(décroissant)'
  },
  {
    label: 'Durée',
    type: 'days',
    value: 'days:asc',
    description: '(croissant)'
  },
  {
    label: 'Durée',
    type: 'days',
    value: 'days:desc',
    description: '(décroissant)'
  },

  {
    label: 'Les mieux notés',
    type: 'ratings',
    value: 'ratings:asc'
  },
  {
    label: 'Les best-sellers',
    type: 'best_sellers',
    value: 'best_sellers'
  }
];

export const CATEGORY_NAMES: Record<TRAVEL_STYLE_SLUG, string> = {
  [TRAVEL_STYLE_SLUG.CIRCUIT_ACCOMPAGNE]: 'Circuit Accompagné',
  [TRAVEL_STYLE_SLUG.CRUISE]: 'Croisière',
  [TRAVEL_STYLE_SLUG.ROADTRIP_AUTOTOUR]: 'Autotour',
  [TRAVEL_STYLE_SLUG.SEJOUR]: 'Séjour',
  [TRAVEL_STYLE_SLUG.FIT]: 'Voyage sur Mesure',
  [TRAVEL_STYLE_SLUG.EXTENSION]: 'Extension',
  [TRAVEL_STYLE_SLUG.SAFARI]: 'Safari',
  [TRAVEL_STYLE_SLUG.EXCURSION]: 'Excursion'
};

export const CONTINENT_NAMES: Record<string, string> = {
  [Continent.AFRIQUE]: 'Afrique & Océan Indien',
  [Continent.ASIE]: 'Asie',
  [Continent.EUROPE]: 'Europe',
  [Continent.OCEANIE]: 'Australie & Iles du pacifique',
  [Continent.AMERIQUES]: 'Amérique',
  [Continent.ORIENT]: 'Orient',
  [WpContinent.AMERIQUE_NORD]: 'Amérique du nord',
  [WpContinent.AMERIQUE_LATINE]: 'Amérique du sud',
  [WpContinent.ASIE_NORD]: 'Chine',
  [WpContinent.INDE]: 'Inde'
};

// ICONS

export const HOUSE_CODE_ICONS: Record<string, string> = {
  [HouseCode.AFRICA_AND_INDIAN_OCEAN]: 'picto-africa-and-indian-ocean',
  [HouseCode.NORTH_AMERICA]: 'picto-north-america',
  [HouseCode.SOUTH_AMERICA]: 'picto-south-america',
  [HouseCode.ASIA]: 'picto-asia',
  [HouseCode.CHINA]: 'picto-china',
  [HouseCode.EUROPE]: 'picto-europe',
  [HouseCode.INDIA]: 'picto-india',
  [HouseCode.AUSTRALIA_AND_PACIFIC_ISLANDS]: 'picto-australia-and-pacific-islands',
  [HouseCode.EAST]: 'picto-east'
};

export enum TRAVEL_CULTURE_TYPE {
  'gastronomie' = 'Gastronomie',
  'cultureAndTraditions' = 'Culture et traditions',
  'holidaysAndFestivals' = 'Fêtes et festivals',
  'bibliography' = 'Bibliographie',
  'citiesAndSteps' = 'Villes et étapes',
  'timeZonesAndGeography' = 'Fuseaux horaires et géographie',
  'travelGuide' = 'Guide de voyage',
  'whenToGo' = 'Quand partir',
  'other' = 'Autre'
}

/**
 * Used for PDF generation
 */
export enum GeographicZoneSlugAlt {
  ASIA = 'Asie',
  AFRICA = 'Afrique',
  CHINA = 'Chine',

  EUROPE = 'Europe',
  INDIA = 'Inde',
  OCEANIA = 'Océanie',

  NORTH_AMERICA = 'Amérique du Nord',
  LATIN_AMERICA = 'Amérique Latine',
  ORIENT = 'Orient'
}

/**
 * Used for PDF generation
 */
export const GEOGRAPHIC_ZONE_ICONS_ALT: Record<GeographicZoneSlugAlt, string> = {
  [GeographicZoneSlugAlt.ASIA]: 'picto-asia',
  [GeographicZoneSlugAlt.AFRICA]: 'picto-africa-and-indian-ocean',
  [GeographicZoneSlugAlt.CHINA]: 'picto-china',

  [GeographicZoneSlugAlt.EUROPE]: 'picto-europe',
  [GeographicZoneSlugAlt.INDIA]: 'picto-india',
  [GeographicZoneSlugAlt.OCEANIA]: 'picto-australia-and-pacific-islands',

  [GeographicZoneSlugAlt.NORTH_AMERICA]: 'picto-north-america',
  [GeographicZoneSlugAlt.LATIN_AMERICA]: 'picto-south-america',
  [GeographicZoneSlugAlt.ORIENT]: 'picto-east'
} as const;

export const GEOGRAPHIC_ZONE_MAP_ICONS: Record<GeographicZoneSlugAlt, string> = {
  [GeographicZoneSlugAlt.ASIA]: 'picto-map-asie',
  [GeographicZoneSlugAlt.AFRICA]: 'picto-map-africa',
  [GeographicZoneSlugAlt.CHINA]: 'picto-map-china',

  [GeographicZoneSlugAlt.EUROPE]: 'picto-map-europe',
  [GeographicZoneSlugAlt.INDIA]: 'picto-map-india',
  [GeographicZoneSlugAlt.OCEANIA]: 'picto-map-oceania',

  [GeographicZoneSlugAlt.NORTH_AMERICA]: 'picto-map-north-america',
  [GeographicZoneSlugAlt.LATIN_AMERICA]: 'picto-map-latine-america',
  [GeographicZoneSlugAlt.ORIENT]: 'picto-map-orient'
} as const;

/**
 * Used for PDF generation
 */
export const GEOGRAPHIC_ZONE_PHONE_NUMBERS: Record<GeographicZoneSlugAlt, string> = {
  Asie: '01 40 51 95 15',
  Afrique: '01 56 81 38 29',
  Chine: '01 40 51 95 00',
  Europe: '01 56 81 38 30',
  Inde: '01 56 81 38 38',
  Océanie: '01 70 36 35 40',
  [GeographicZoneSlugAlt.NORTH_AMERICA]: '01 53 63 13 43',
  [GeographicZoneSlugAlt.LATIN_AMERICA]: '01 53 63 13 40',
  Orient: '01 56 81 38 30'
} as const;

export const GEOGRAPHIC_ZONE_MAP_IMAGES: Record<string, string> = {
  [GeographicZoneSlug.ISLANDS]: '/images/geographic-zones/maps/asia.svg', // TODO: change this image when we receive the good one
  [GeographicZoneSlug.EUROPE]: '/images/geographic-zones/maps/europe.svg',
  [GeographicZoneSlug.EAST]: '/images/geographic-zones/maps/east.svg', // The good one
  [GeographicZoneSlug.AFRICA_AND_INDIAN_OCEAN]:
    '/images/geographic-zones/maps/africa-and-indian-ocean.svg',
  [GeographicZoneSlug.ASIA]: '/images/geographic-zones/maps/asia.svg',
  [GeographicZoneSlug.AUSTRALIA_AND_PACIFIC_ISLANDS]:
    '/images/geographic-zones/maps/australia-and-pacific-islands.svg',
  [GeographicZoneSlug.AMERICA]: '/images/geographic-zones/maps/america.svg'
};

export const GEOGRAPHIC_ZONE_BACKGROUND_IMAGES: Record<string, string> = {
  [GeographicZoneSlug.ISLANDS]: '/images/geographic-zones/backgrounds/asia.svg',
  [GeographicZoneSlug.EUROPE]: '/images/geographic-zones/backgrounds/europe.svg',
  [GeographicZoneSlug.EAST]: '/images/geographic-zones/backgrounds/east.svg',
  [GeographicZoneSlug.AFRICA_AND_INDIAN_OCEAN]:
    '/images/geographic-zones/backgrounds/africa-and-indian-ocean.svg',
  [GeographicZoneSlug.ASIA]: '/images/geographic-zones/backgrounds/asia.svg',
  [GeographicZoneSlug.AUSTRALIA_AND_PACIFIC_ISLANDS]:
    '/images/geographic-zones/backgrounds/australia-and-pacific-islands.svg',
  [GeographicZoneSlug.AMERICA]: '/images/geographic-zones/backgrounds/america.svg'
};

export const GEOGRAPHIC_ZONE_SLUGS: Record<string, string> = {
  [HouseCode.AFRICA_AND_INDIAN_OCEAN]: 'afrique',
  [HouseCode.NORTH_AMERICA]: 'amerique',
  [HouseCode.SOUTH_AMERICA]: 'amerique',
  [HouseCode.ASIA]: 'asie',
  [HouseCode.CHINA]: 'chine',
  [HouseCode.EUROPE]: 'europe',
  [HouseCode.INDIA]: 'asie',
  [HouseCode.AUSTRALIA_AND_PACIFIC_ISLANDS]: 'oceanie',
  [HouseCode.EAST]: 'orient'
};

export const TRAVEL_TYPE_ICONS: Record<TRAVEL_STYLE_SLUG, string> = {
  [TRAVEL_STYLE_SLUG.CIRCUIT_ACCOMPAGNE]: 'circuit-accompagne',
  [TRAVEL_STYLE_SLUG.CRUISE]: 'croisieres',
  [TRAVEL_STYLE_SLUG.ROADTRIP_AUTOTOUR]: 'roadtrip-et-autotour',
  [TRAVEL_STYLE_SLUG.SEJOUR]: 'sejour',
  [TRAVEL_STYLE_SLUG.FIT]: 'voyage-sur-mesure',
  [TRAVEL_STYLE_SLUG.EXTENSION]: 'extension',
  [TRAVEL_STYLE_SLUG.SAFARI]: 'safari',
  [TRAVEL_STYLE_SLUG.EXCURSION]: 'excursion'
};

export const TRAVEL_STYLE_PLURALS: Record<string, { plural: string; all: string; title: string }> =
  {
    autotour: { plural: 'les autotours', all: 'tous', title: 'Autotour' },
    'circuit-accompagne': {
      plural: 'les circuits accompagnés',
      all: 'tous',
      title: 'Circuit accompagné'
    },
    combine: { plural: 'les combinés', all: 'tous', title: 'Combiné' },
    croisiere: { plural: 'les croisières', all: 'toutes', title: 'Croisière' },
    ecotourisme: { plural: 'les voyages écotourismes', all: 'tous', title: 'Écotourisme' },
    'location-d-appartement': {
      plural: "les locations d'appartement",
      all: 'toutes',
      title: "Location d'appartement"
    },
    'modules-et-excursions': {
      plural: 'les Modules et excursions',
      all: 'tous',
      title: 'Modules et excursions'
    },
    safari: { plural: 'les safaris', all: 'tous', title: 'Safari' },
    sejour: { plural: 'les séjours', all: 'tous', title: 'Séjour' },
    'voyage-de-noces': { plural: 'les voyages de noces', all: 'tous', title: 'Voyage de noces' },
    'voyage-en-train': { plural: 'les voyages en train', all: 'tous', title: 'Voyage en train' },
    'voyage-en-vespa': { plural: 'les voyages en Vespa', all: 'tous', title: 'Voyage en vespa' },
    'voyage-sur-mesure': {
      plural: 'les voyages sur mesure',
      all: 'tous',
      title: 'Voyage sur mesure'
    },
    'voyager-seul': { plural: 'les voyages en solo', all: 'tous', title: 'Voyager seul' },
    'week-end': { plural: 'les week-ends', all: 'tous', title: 'Week-end' },
    'a-deux': { plural: 'les voyages à deux', all: 'tous', title: 'Voyage à deux' },
    'art-culture': { plural: 'les voyages arts et culture', all: 'tous', title: 'Art & culture' },
    'autour-d-un-evenement': {
      plural: "les voyages autour d'un évènement",
      all: 'tous',
      title: "Autour d'un évènement"
    },
    'aventure-et-randonnee': {
      plural: 'les voyages aventures et randonnées',
      all: 'tous',
      title: 'Aventure et randonnée'
    },
    'bien-etre-detente-et-spa': {
      plural: 'les voyages bien-être, détente et spas',
      all: 'tous',
      title: 'Bien être'
    },
    'bien-etre-et-onsen': {
      plural: 'les voyages bien-être et onsens',
      all: 'tous',
      title: 'Bien-être et onsen'
    },
    'en-famille': { plural: 'les voyages en famille', all: 'tous', title: 'Voyage en famille' },
    'ete-et-nuits-blanches': {
      plural: 'les voyages étés et nuits blanches',
      all: 'tous',
      title: 'Eté & nuits blanches'
    },
    'explorers-way': {
      plural: 'les voyages route des explorateurs',
      all: 'tous',
      title: 'La route des explorateurs'
    },
    'fetes-et-festivals': {
      plural: 'les voyages fêtes et festivals',
      all: 'tous',
      title: 'Fêtes et festivals'
    },
    gastronomie: { plural: 'les voyages gastronomie', all: 'tous', title: 'Gastronomie' },
    'gastronomie-et-oenologie': {
      plural: 'les voyages gastronomie et œnologie',
      all: 'tous',
      title: 'Gastronomie & oenologie'
    },
    'hiver-et-aurores-boreales': {
      plural: 'les voyages hivers et aurores boréales',
      all: 'tous',
      title: 'Hiver & aurores boréales'
    },
    'hors-des-sentiers-battus': {
      plural: 'les voyages hors des sentiers battus',
      all: 'tous',
      title: 'Hors des sentiers battus'
    },
    'les-plus-belles-plages': {
      plural: 'les plus belles plages',
      all: 'toutes',
      title: 'Les plus belles plages'
    },
    'luxe-prestige': {
      plural: 'les voyages Luxe et prestige',
      all: 'tous',
      title: 'Luxe & prestige'
    },
    nature: { plural: 'les voyages nature', all: 'tous', title: 'Voyage nature' },
    'nos-plus-belles-plages': {
      plural: 'nos plus belles plages',
      all: 'toutes',
      title: 'Nos plus belles plages'
    },
    'plage-et-farniente': {
      plural: 'les voyages plages et farniente',
      all: 'tous',
      title: 'Plage & Farniente'
    },
    'spa-et-detente': {
      plural: 'les voyages spas et détente',
      all: 'tous',
      title: 'Spa et détente'
    },
    'tours-du-monde-combines': {
      plural: 'les tours du monde et combinés',
      all: 'tous',
      title: 'Tours du monde & combinés'
    },
    'trek-aventure': {
      plural: 'les voyages treks et aventures',
      all: 'tous',
      title: 'Trek & aventure'
    },
    'trek-et-randonnee': {
      plural: 'les voyages treks et randonnées',
      all: 'tous',
      title: 'Trek et randonnée'
    },
    'voyage-a-deux': { plural: 'les voyages en couple', all: 'tous', title: 'Voyage à deux' },
    'voyage-en-famille': {
      plural: 'les voyages en famille',
      all: 'tous',
      title: 'Voyage en famille'
    },
    'voyage-plongee': { plural: 'les voyages plongée', all: 'tous', title: 'Voyage plongée' }
  };

export const INSPIRATION_CATEGORY_ICONS: Record<INSPIRATION_CATEGORY_SLUG, string> = {
  [INSPIRATION_CATEGORY_SLUG.SPA]: 'spa',
  [INSPIRATION_CATEGORY_SLUG.CIRCUIT_ACCOMPAGNE]: 'circuit-accompagne',
  [INSPIRATION_CATEGORY_SLUG.CROISIERES]: 'croisieres',
  [INSPIRATION_CATEGORY_SLUG.ECOTOURISME]: 'ecotourisme',
  [INSPIRATION_CATEGORY_SLUG.FETES_ET_FESTIVALS]: 'fetes-et-festivals',
  [INSPIRATION_CATEGORY_SLUG.HORS_DES_SENTIERS_BATTUS]: 'hors-des-sentiers-battus',
  [INSPIRATION_CATEGORY_SLUG.ROADTRIP_AUTOTOUR]: 'roadtrip-et-autotour',
  [INSPIRATION_CATEGORY_SLUG.SAFARI]: 'safari',
  [INSPIRATION_CATEGORY_SLUG.TREK_ET_RANDONNEE]: 'trek-and-randonnee',
  [INSPIRATION_CATEGORY_SLUG.VOYAGE_CULTUREL]: 'voyage-culturel',
  [INSPIRATION_CATEGORY_SLUG.VOYAGE_DE_LUXE]: 'voyage-de-luxe',
  [INSPIRATION_CATEGORY_SLUG.VOYAGE_DE_NOCES]: 'voyage-de-noces',
  [INSPIRATION_CATEGORY_SLUG.VOYAGE_EN_FAMILLE]: 'voyage-en-famille',
  [INSPIRATION_CATEGORY_SLUG.VOYAGE_EN_TRAIN]: 'voyage-en-train',
  [INSPIRATION_CATEGORY_SLUG.WEEK_END]: 'week-end'
};

export const HEADER_MENUS: HEADER_MENUS_ENUM[] = [
  HEADER_MENUS_ENUM.DESTINATION,
  HEADER_MENUS_ENUM.INSPIRATIONS,
  HEADER_MENUS_ENUM.WHEN_WHERE_TO_GO,
  HEADER_MENUS_ENUM.WHO_ARE_WE,
  HEADER_MENUS_ENUM.CULTURE_AND_TRAVEL,
  HEADER_MENUS_ENUM.SPECIAL_OFFERS,
  HEADER_MENUS_ENUM.CONTACT_REFERENT
];

export const typeAndWishCategoriesFilters: FilterDefinition<'typeCategories'>[] = Object.keys(
  TRAVEL_STYLE_PLURALS
).map(key => {
  const style = TRAVEL_STYLE_PLURALS[key];
  return {
    id: key,
    identifier: 'typeCategories',
    name: style?.title ?? '',
    value: key,
    icon: key
  };
});

export const COUNTRIES_ALPHA_2 = {
  AF: 'Afghanistan',
  ZA: 'Afrique Du Sud',
  AL: 'Albanie',
  DZ: 'Algérie',
  DE: 'Allemagne',
  AD: 'Andorre',
  AO: 'Angola',
  AI: 'Anguilla',
  AQ: 'Antarctique',
  AG: 'Antigua-Et-Barbuda',
  SA: 'Arabie Saoudite',
  AR: 'Argentine',
  AM: 'Arménie',
  AW: 'Aruba',
  AU: 'Australie',
  AT: 'Autriche',
  AZ: 'Azerbaïdjan',
  BS: 'Bahamas',
  BH: 'Bahreïn',
  BD: 'Bangladesh',
  BB: 'Barbad',
  BE: 'Belgique',
  BZ: 'Belize',
  BM: 'Bermudes',
  BT: 'Bhoutan',
  MM: 'Birmanie',
  BY: 'Biélorussie',
  BQ: 'Bonaire, Saint-Eustache Et Saba',
  BA: 'Bosnie-Herzégovine',
  BW: 'Botswana',
  BN: 'Brunei Darussalam',
  BR: 'Brésil',
  BG: 'Bulgarie',
  BF: 'Burkina Faso',
  BI: 'Burundi',
  BJ: 'Bénin',
  KH: 'Cambodge',
  CM: 'Cameroun',
  CA: 'Canada',
  CV: 'Cap-Vert',
  CL: 'Chili',
  CN: 'Chine',
  CY: 'Chypre',
  CO: 'Colombie',
  KM: 'Comores',
  CG: 'Congo',
  CR: 'Costa Rica',
  HR: 'Croatie',
  CU: 'Cuba',
  CW: 'Curaçao',
  CI: "Côte D'Ivoire",
  DK: 'Denmark',
  DJ: 'Djibouti',
  DM: 'Dominique',
  ES: 'Espagne',
  EE: 'Estonie',
  EU: 'Europe',
  FJ: 'Fidji',
  FI: 'Finlande',
  FR: 'France',
  RU: 'Fédération De Russie',
  GA: 'Gabon',
  GM: 'Gambie',
  GH: 'Ghana',
  GI: 'Gibraltar',
  GD: 'Grenade',
  GL: 'Groenland',
  GR: 'Grèce',
  GP: 'Guadeloupe',
  GU: 'Guam',
  GT: 'Guatemala',
  GG: 'Guernesey',
  GN: 'Guinée',
  GQ: 'Guinée Équatoriale',
  GW: 'Guinée-Bissau',
  GY: 'Guyana',
  GF: 'Guyane',
  GE: 'Géorgie',
  GS: 'Géorgie Du Sud-Et-Les Îles Sandwich Du Sud',
  HT: 'Haïti',
  HN: 'Honduras',
  HK: 'Hong Kong',
  HU: 'Hongrie',
  IN: 'Inde',
  ID: 'Indonésie',
  IQ: 'Irak',
  IE: 'Irlande',
  IS: 'Islande',
  IL: 'Israël',
  IT: 'Italie',
  JM: 'Jamaïque',
  JP: 'Japon',
  JE: 'Jersey',
  JO: 'Jordanie',
  KZ: 'Kazakhstan',
  KE: 'Kenya',
  KG: 'Kirghizistan',
  KI: 'Kiribati',
  KW: 'Koweït',
  LS: 'Lesotho',
  LV: 'Lettonie',
  LB: 'Liban',
  LR: 'Liberia',
  LY: 'Libye',
  LI: 'Liechtenstein',
  LT: 'Lituanie',
  LU: 'Luxembourg',
  MO: 'Macao',
  MK: 'Macédoine',
  MG: 'Madagascar',
  MY: 'Malaisie',
  MW: 'Malawi',
  MV: 'Maldives',
  ML: 'Mali',
  MT: 'Malte',
  MA: 'Maroc',
  MQ: 'Martinique',
  MU: 'Maurice',
  MR: 'Mauritanie',
  YT: 'Mayotte',
  MX: 'Mexique',
  MC: 'Monaco',
  MN: 'Mongolie',
  MS: 'Montserrat',
  ME: 'Monténégro',
  MZ: 'Mozambique',
  NA: 'Namibie',
  NR: 'Nauru',
  NI: 'Nicaragua',
  NE: 'Niger',
  NG: 'Nigéria',
  NU: 'Niue',
  NO: 'Norvège',
  NC: 'Nouvelle-Calédonie',
  NZ: 'Nouvelle-Zélande',
  NP: 'Népal',
  OM: 'Oman',
  UG: 'Ouganda',
  UZ: 'Ouzbékistan',
  PK: 'Pakistan',
  PW: 'Palaos',
  PA: 'Panama',
  PG: 'Papouasie-Nouvelle-Guinée',
  PY: 'Paraguay',
  NL: 'Pays-Bas',
  PH: 'Philippines',
  PN: 'Pitcairn',
  PL: 'Pologne',
  PF: 'Polynésie Française',
  PR: 'Porto Rico',
  PT: 'Portugal',
  PE: 'Pérou',
  QA: 'Qatar',
  RO: 'Roumanie',
  GB: 'Royaume-Uni',
  RW: 'Rwanda',
  AP: 'Région Asie/Pacifique',
  SY: 'République Arabe Syrienne',
  CF: 'République Centrafricaine',
  KR: 'République De Corée',
  MD: 'République De Moldavie',
  DO: 'République Dominicaine',
  SV: 'République Du Salvador',
  CD: 'République Démocratique Du Congo',
  LA: 'République Démocratique Populaire Lao',
  IR: "République Islamique D'Iran",
  KP: 'République Populaire Démocratique De Corée',
  CZ: 'République Tchèque',
  TZ: 'République-Unie De Tanzanie',
  RE: 'Réunion',
  EH: 'Sahara Occidental',
  BL: 'Saint-Barthélemy',
  KN: 'Saint-Christophe-et-Niévès',
  SM: 'Saint-Marin',
  MF: 'Saint-Martin (Partie Française)',
  SX: 'Saint-Martin (Partie Néerlandaise)',
  PM: 'Saint-Pierre-Et-Miquelon',
  VA: 'Saint-Siège (État De La Cité Du Vatican)',
  VC: 'Saint-Vincent-Et-Les Grenadines',
  SH: 'Sainte-Hélène',
  LC: 'Sainte-Lucie',
  WS: 'Samoa',
  AS: 'Samoa Américaines',
  ST: 'Sao Tomé-Et-Principe',
  RS: 'Serbie',
  SC: 'Seychelles',
  SL: 'Sierra Leone',
  SG: 'Singapour',
  SK: 'Slovaquie',
  SI: 'Slovénie',
  SO: 'Somalie',
  SD: 'Soudan',
  SS: 'Soudan Du Sud',
  LK: 'Sri Lanka',
  CH: 'Suisse',
  SR: 'Suriname',
  SE: 'Suède',
  SJ: 'Svalbard Et Jan Mayen',
  SZ: 'Swaziland',
  SN: 'Sénégal',
  TJ: 'Tadjikistan',
  TW: 'Taïwan',
  TD: 'Tchad',
  TF: 'Terres Australes Françaises',
  IO: "Territoire Britannique De L'océan Indien",
  PS: 'Territoires Palestiniens Occupés',
  TH: 'Thaïlande',
  TL: 'Timor-Leste',
  TG: 'Togo',
  TK: 'Tokelau',
  TO: 'Tonga',
  TT: 'Trinité-Et-Tobago',
  TN: 'Tunisie',
  TM: 'Turkménistan',
  TR: 'Turquie',
  TV: 'Tuvalu',
  UA: 'Ukraine',
  UY: 'Uruguay',
  VU: 'Vanuatu',
  VE: 'Venezuela',
  VN: 'Viet Nam',
  WF: 'Wallis Et Futuna',
  YE: 'Yémen',
  ZM: 'Zambie',
  ZW: 'Zimbabwe',
  EG: 'Égypte',
  AE: 'Émirats Arabes Unis',
  EC: 'Équateur',
  ER: 'Érythrée',
  BO: 'État Plurinational De Bolivie',
  FM: 'États Fédérés De Micronésie',
  US: 'États-Unis',
  ET: 'Éthiopie',
  BV: 'Île Bouvet',
  CX: 'Île Christmas',
  IM: 'Île De Man',
  NF: 'Île Norfolk',
  KY: 'Îles Caïmans',
  CC: 'Îles Cocos',
  CK: 'Îles Cook',
  FO: 'Îles Féroé',
  HM: 'Îles Heard-Et-MacDonald',
  FK: 'Îles Malouines',
  MP: 'Îles Mariannes Du Nord',
  MH: 'Îles Marshall',
  UM: 'Îles Mineures Éloignées Des États-Unis',
  SB: 'Îles Salomon',
  TC: 'Îles Turks-Et-Caïcos',
  VG: 'Îles Vierges Britanniques',
  VI: 'Îles Vierges Des États-Unis',
  AX: 'Îles Åland'
};

export type IsoCode = keyof typeof COUNTRIES_ALPHA_2;

export const PRODUCT_LABEL_NAMES = {
  [PRODUCT_LABEL.NONE]: undefined,
  [PRODUCT_LABEL.COUP_DE_COEUR]: 'Coup de coeur',
  [PRODUCT_LABEL.NOTRE_COUP_DE_COEUR]: 'Notre coup de coeur',
  [PRODUCT_LABEL.DERNIERES_PLACES]: 'Dernières places',
  [PRODUCT_LABEL.NOUVEAUTE]: 'Nouveauté',
  [PRODUCT_LABEL.PROMOTIONS]: 'Promotions',
  [PRODUCT_LABEL.BESTSELLER]: 'Bestseller',
  [PRODUCT_LABEL.EVENEMENT]: 'Evenement',
  [PRODUCT_LABEL.PARTENARIAT]: 'Partenariat',
  [PRODUCT_LABEL.ECOTOURISME]: 'Ecotourisme',
  [PRODUCT_LABEL.INEDIT]: 'Inédit',
  [PRODUCT_LABEL.DEPART_GARANTI]: 'Départ garanti',
  [PRODUCT_LABEL._2019_PRET_A_PARTIR]: '2019, prêt à partir',
  [PRODUCT_LABEL._2020_PRET_A_PARTIR]: '2020, prêt à partir',
  [PRODUCT_LABEL.CIRCUIT_MAISON]: 'Circuit Maison',
  [PRODUCT_LABEL.MILLESIME]: 'Millésime'
};

export const BREADCRUMB = {
  home: 'Accueil'
};

/** Maps the block.category.type to a logo */
export const BLOCK_TYPE_TO_LOGO = {
  Accomodation: 'accomodation',
  Activity: 'activity',
  Car: 'car',
  Transportation: 'transportation',

  GoodToKnow: 'activity',
  Meal: 'activity',
  City: 'map',
  Miscellaneous: 'activity',
  Place: 'map',
  StrongPoint: 'map'
} as const;

export const VISA_STATUS = {
  required: 'requis',
  notRequired: 'non requis'
};

export const FLIGHT_ENTRIES_ICONS = {
  flight: 'flight',
  jetlag: 'jetlag',
  visa: 'passport'
};

export const FLIGHT_ENTRIES_NAMES = {
  flight: 'Temps de vol moyen',
  jetlag: 'Décalage horaire',
  visa: 'Visa'
};

export const PROPOSITION_LEFT_MENU = {
  [PROPOSITION_LEFT_MENU_ENUM.ESSENTIAL]: "L'essentiel",
  [PROPOSITION_LEFT_MENU_ENUM.ITINERARY]: 'Itinéraire',
  [PROPOSITION_LEFT_MENU_ENUM.PRICE]: 'Prix',
  [PROPOSITION_LEFT_MENU_ENUM.ACCOMODATION]: 'Hébergement',
  [PROPOSITION_LEFT_MENU_ENUM.FORMALITIES]: 'Formalités',
  [PROPOSITION_LEFT_MENU_ENUM.EXPERT]: 'Votre conseiller'
};

export const NUMBER_OF_DAYS_BEFORE_FULL_PRICE_DEPOSIT = 45;

export const SECURITY_HEADERS = {
  'Strict-Transport-Security': 'max-age=31536000',
  'X-Frame-Options': 'SAMEORIGIN',
  'X-Content-Type-Options': 'nosniff',
  'Referrer-Policy': 'strict-origin-when-cross-origin',
  'Permissions-Policy':
    'accelerometer=(), geolocation=(self), fullscreen=(), ambient-light-sensor=(), autoplay=(), battery=(), camera=(), display-capture=(self)',
  'X-Powered-By': 'Les Maisons du Voyage'
};

/**
 * Name of the query param used to pre-fill the tailored quote.
 * Ex: 'voyage' in /devis-sur-mesure?voyage=123
 * */
export const JOURNEY_QUERY_PARAM = 'voyage';
